import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'queryResponse',
  initialState: {
    stats: { total: '0', verified: '0', completed: '0' },
    finalResult: [],
    filters: {}
  },
  reducers: {
    setCards: (state, action) => {
      state.stats = action.payload
    },
    setGraphs: (state, action) => {
      state.finalResult = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    }
  }
})
export const { setCards, setGraphs, setFilters } = slice.actions
export default slice.reducer
