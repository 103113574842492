export const FILTER_OPTIONS = [
  '1d',
  '7d',
  '1m',
  '3m',
  '6m',
  '1y',
  'All',
  'Custom'
]
export const BG_COLOR_ACTIVE = '#FFFFFF'
export const BG_COLOR_DEACTIVE = '#F7FAFE'

export const TEXT_COLOR_ACTIVE = '#367f98'
export const TEXT_COLOR_DEACTIVE = '#94a2b3'
export const DEFAULT_FILTER_OPTION = '7d'
export const CUSTOM_FILTER_OPTION = 'Custom'

export const BG_SAVE_BUTTON_ACTIVE = '#367F98'
export const BG_SAVE_BUTTON_DEACTIVE = '#FFFFFF'

export const TEXT_COLOR = '#272D4E'

export const TOTAL_LEGEND_COLOR = '#7546A6'
export const COMPLETED_LEGEND_COLOR = '#35d130'
export const VERIFIED_LEGEND_COLOR = '#5679B7'
export const TOTAL_LEGEND_COLOR_TRANS = '#835aaf26'
export const COMPLETED_LEGEND_COLOR_TRANS = '#91c7ef26'
export const VERIFIED_LEGEND_COLOR_TRANS = '#5679b726'

export const REFERRED_LEGEND_COLOR = '#E6C437'
export const VALID_LEGEND_COLOR = '#63D3D1'
export const INVALID_LEGEND_COLOR = '#F37C29'
export const REFERRED_LEGEND_COLOR_TRANS = '#835aaf26'
export const VALID_LEGEND_COLOR_TRANS = '#91c7ef26'
export const INVALID_LEGEND_COLOR_TRANS = '#5679b726'
export const TOTAL_SCREENER = '#437FEF'
export const TOTAL_REJECTED = '#FF0000'

export const COMPLETED_SCREENER = '#6DD230'
export const UNPROCESSES_SCREENER = '#f759ab'
export const VALID_SCREENER = '#23B899'
export const INVALID_SCREENER = '#FE7C4B'
export const WAITING_SCREENER = '#FFD112'
export const COMPLETED_SCREENER_BOARD = '#6DD230'

export const NOT_EXPIRED_BOARD = '#8978FF'
export const NOT_EXPIRED_TEXT = 'Not expired'
export const NOT_ANSWERED_BOARD = '#80392e'
export const NOT_ANSWERED_TEXT = 'Not answered'

export const EXPIRED_BOARD = '#f20c23'
export const EXPIRED_BOARD_TEXT = 'Expired'
export const VALID_COLOR_BOARD = '#3CC6A9'
export const VALID_TEXT_BOARD = 'Valid'
export const INVALID_COLOR_BOARD = '#3CC6A9'
export const INVALID_TEXT_BOARD = 'Invalid'
export const UNPROCESSES_BOARD_TEXT = 'Unprocessed'

export const TOTAL_LEGEND_TEXT = 'Total Users'
export const COMPLETED_LEGEND_TEXT = 'Completed Users'
export const VERIFIED_LEGEND_TEXT = 'Verified Users'
export const REFERRED_LEGEND_TEXT = 'Users gained from shares'
export const VALID_LEGEND_TEXT = 'Users valid Surveys'
export const INVALID_LEGEND_TEXT = 'Users invalid Surveys'

export const TOTAL_SCREENER_TEXT = 'Total Enrollments'
export const WAITING_SCREENER_TEXT = 'Waiting for products'
export const COMPLETED_SCREENER_TEXT = 'Completed Surveys'
export const COMPLETED_SCREENER_TEXT_BOARD = 'Completed Enrollments'
export const UNPROCESSES_SCREENER_TEXT = 'Unprocessed Surveys'

export const VALID_SCREENER_TEXT = 'Valid Responses'
export const INVALID_SCREENER_TEXT = 'Invalid Responses'

export const FORMATED_OPTIONS = {
  '1d': '1 day',
  '7d': '7 days',
  '1m': '1 month',
  '3m': '3 months',
  '6m': '6 months',
  '1y': '1 year',
  All: 'All data',
  Custom: 'Custom'
}

export const SURVEY_TYPE_OPTIONS = {
  preRecruit: {
    value: 'pre-recruit',
    label: 'Pre-recruit'
  },
  marketResearch: {
    value: 'market-research',
    label: 'Market research'
  }
}

export const DATABOARD_DAILY_VALID_RATE = '#ffd666'
