import { combineReducers } from 'redux'
import authenticationReducer from '@/store/authentication'
import userReducer from '@/store/users'
import boardsReducer from '@/store/boards'
import queryReducer from '@/store/queries'
import responseReducer from '@/store/response'
import screenerReducer from '@/store/screenersQueries'
import screenerResponse from '@/store/screenersResponse'
import projectResponse from '@/store/projectBoardQueries'
import projectBoardResponse from '@/store/projectBoardResponseQueries'
// import BoardCreatedReducer from '@/store/projectBoardCreatedQueries'

const reducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  boards: boardsReducer,
  query: queryReducer,
  queryResponse: responseReducer,
  screenerQuery: screenerReducer,
  screenerResponse: screenerResponse,
  projectBoardQuery: projectResponse,
  projectBoardResponseQuery: projectBoardResponse
})
export default reducer
