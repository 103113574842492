import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  surveyName: '',
  selectedSurveyId: '',
  selectedSurveyName: '',
  survey: '',
  option: '',
  startDate: null,
  endDate: null,
  valid: false,
  invalid: false,
  surveyCountry: '',
  surveyImage: '',
  surveyCap: false,
  surveyCapOptions: [],
  surveyCapSelectedOptions: []
}

const slice = createSlice({
  name: 'screenerQuery',
  initialState,
  reducers: {
    setScreenerOption: (state, action) => {
      state.option = action.payload
    },
    setScreenerStartDate: (state, action) => {
      return { ...state, startDate: action.payload }
    },
    setScreenerEndDate: (state, action) => {
      return { ...state, endDate: action.payload }
    },
    setSelectedSurvey: (state, action) => {
      state.survey = action.payload
    },
    setSurveyName: (state, action) => {
      state.surveyName = action.payload
    },
    setSelectedSurveyId: (state, action) => {
      state.selectedSurveyId = action.payload
    },
    setSelectedSurveyName: (state, action) => {
      state.selectedSurveyName = action.payload
    },
    setValidResponse: (state, action) => {
      state.valid = action.payload
    },
    setInvalidResponse: (state, action) => {
      state.invalid = action.payload
    },
    setSurveyCap: (state, action) => {
      state.surveyCap = action.payload
    },
    setSurveyCapOptions: (state, action) => {
      state.surveyCapOptions = action.payload
    },
    setSurveyCountry: (state, action) => {
      state.surveyCountry = action.payload
    },
    setSurveyImage: (state, action) => {
      state.surveyImage = action.payload
    },
    setSurveyCapSelectedOptions: (state, action) => {
      state.surveyCapSelectedOptions = action.payload
    },

    clearScreenerAllFilters: state => {
      state.option = '7d'
      state.startDate = ''
      state.endDate = ''
      state.valid = false
      state.invalid = false
      state.surveyCap = false
      state.surveyCapOptions = []
      state.surveyCapSelectedOptions = []
    },
    setScreenerQuery: (state, action) => {
      const { option, valid, invalid, survey, surveyCap } = action.payload
      state.option = option
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate)
        : null
      state.endDate = action.payload.startDate
        ? new Date(action.payload.endDate)
        : null
      state.valid = valid
      state.invalid = invalid
      state.survey = survey
      state.surveyCap = surveyCap
    }
  }
})
export const {
  setSelectedSurvey,
  setValidResponse,
  setInvalidResponse,
  setSurveyCap,
  setSurveyCapOptions,
  setScreenerOption,
  setScreenerStartDate,
  setScreenerEndDate,
  setSurveyName,
  setSelectedSurveyId,
  setSelectedSurveyName,
  clearScreenerAllFilters,
  setScreenerQuery,
  setSurveyCountry,
  setSurveyImage,
  setSurveyCapSelectedOptions
} = slice.actions
export default slice.reducer
