import { GET, POST, DELETE } from '@/utils/axios'

/**
  PROJECT APIs
 */
export const projectBySearch = async (search) =>
  await GET(`/project/list/${search}`, { useAuthorization: true })

/**
  PROJECT-BOARD APIs
 */
//get all boards
export const userBoards = async () =>
  await GET(`/project-board/userboards`, { useAuthorization: true })

export const removeUserBoard = async (id) =>
  await DELETE(`/project-board/${id}`, { useAuthorization: true })

export const getBoard = async (id) =>
  await GET(`/project-board/${id}`, { useAuthorization: true })

export const getBoardStats = async (id, data) =>
  await POST(`/project-board/${id}/stats`, data, { useAuthorization: true })

export const getBoardCharts = async (id, data) =>
  await POST(`/project-board/${id}/charts`, data, { useAuthorization: true })

export const createBoard = async (data) =>
  await POST('/project-board/create', data, { useAuthorization: true })

export const updateBoard = async (id, data) =>
  await POST(`/project-board/${id}`, data, { useAuthorization: true })

export const saveUpdatedBoard = async (data) =>
  await POST('/project-board/remove-card', data, { useAuthorization: true })

export const saveBoardFilters = async (data, boardId) =>
  await POST(`/project-board/${boardId}`, data, { useAuthorization: true })

/**
  SURVEY APIs
 */
// get survey question
export const surveyFilters = async (survey) =>
  await GET(`/survey/question-options?survey=${survey}`, {
    useAuthorization: true
  })

/**
  user-details APIs
 */
export const userStats = async (data) =>
  await POST('/user-details/project-board-userbase-stats', data, {
    useAuthorization: true
  })

export const userGraph = async (data) =>
  await POST('/user-details/proejct-board-userbase-graph', data, {
    useAuthorization: true
  })
