import { createSlice } from '@reduxjs/toolkit'

const boardSlice = createSlice({
  name: 'boards',
  initialState: {
    isLoading: false,
    dataSource: []
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setDataSource: (state, action) => {
      state.dataSource = action.payload
    }
  }
})

export const { setIsLoading, setDataSource } = boardSlice.actions
export default boardSlice.reducer
