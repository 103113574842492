export const DEFAULT_THEME = {
  NAME: 'flavorwiki',
  APP_TITLE: 'FlavorWiki Marketing Application',
  ICON_URL: 'https://tools.flavorwiki.com/default.png',
  FULL_LOGO_URL:
    'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
  ALLOW_SOCIAL_NETWORK: true,
  ALLOW_TASTER: true,
  ALLOW_SHARE: true,
  TOKEN_HOURS: 24,
  PRIMARY_COLOR: '#367F98',
  UNSELECTED_MENU_COLOR: '#262c4e',
  BASIC_BACKGROUND_COLOR: '#272d4e',
  BUTTON_COLOR: '#f7fafe',
  LOADER_BACKGROUND: 'rgba(28, 88, 159, 0.1)',
  LOADING_BACKGROUND: 'rgba(255, 255, 255, 0.75)',
  TEXT_TERMS_COLOR: '#94A2B3',
  CARDS_TEXT_COLOR: '#94a2b3',
  PERCENTAGE_COLOR: '#4ab9bb',
  USER_COUNT_COLOR: 'rgba(39, 45, 78, 0.5)',
  TOGGLE_CARDS_TEXT_COLOR: 'rgba(230, 239, 251, 0.7)',
  TOGGLE_CARDS_COUNT_COLOR: '#e6effb',
  DATE_PICKER_BORDER_COLOR: '#cccccc',
  COUNTER_BORDER_COLOR: '#E0E0E0',
  DATE_PIKCER_COLOR: '#8c8c8c',
  FOOTER_BUTTON_HOVER: '#e6e8eb',
  FOOTER_ICON_BACKGROUND: '#1e9bbb',
  HEADER_RIGHT_ICON: '#4ea3bf',
  SIDEBAR_CHART_ICON_COLOR: '#8c8f9f',
  STEPS_BACKGROUND: '#3196b9',
  STEPS_WAIT_BACKGROUND: '#eff3f9',
  DISCARD_BUTTON_BACKGROUND: '#da2f58',
  DELETE_CHART_BUTTON_BACKGROUND: '#bf2d50',
  DELETE_CHART_BUTTON_COLOR: '#f9dae1',
  CLOSE_ICON_BACKGROUND: '#cf6881',
  SURVEY_NAME_COLOR: '#262727',
  CHECKBOX_COLOR: '#272D4E80',
  BUTTON_DANGER_COLOR: '#ff4d4f',
  FACEBOOK: '#4267B2',
  TWITTER: '#1f9bf0',
  SLATE_GREY: '#4e4e56',
  PALE_LIGHT_GREY: '#e4e4e5',
  LIGHT_GREY: '#cdcdd2',
  CHART_LABEL: '#7f7f7f',
  VERY_LIGHT_GREY: '#f0f0f0',
  ERROR: '#f5222d',
  WHITE: '#ffffff',
  BLACK: '#000000',
  LIGHT_BLUE: '#1890ff',
  SALMON: '#df625f',
  DARK_BACKGROUND: '#fafafa',
  ADMIN_CONTENT_BACKGROUND: '#f0f2f5',
  RED: '#e90000',
  DARKER_RED: '#d10000',
  BLUE_GREEN: '#147a73',
  SHARE: '#1a73e8',
  CARD_BORDER: '#eeeeee',
  SOCIAL_TEXT_COLOR: '#73738d',
  SOCIAL_BUTTON_COLOR: '#eaedf3',
  SELECTER_BORDER: '#e5eaf4',
  TAGS_COLOR: '#e0e1f1',
  BORDER_COLOR: '#5E6A91',
  TGAS_CAPTION: '#aaaaaa'
}
