import { createGlobalStyle } from 'styled-components'
import { darken, rgba } from 'polished'

export const GlobalStyle = createGlobalStyle`
  ::selection {
    color: ${props => props.theme.WHITE};
    background: ${props => props.theme.PRIMARY_COLOR};
  }

  a {
    color: ${props => props.theme.PRIMARY_COLOR};

    &:hover {
      color: ${props => darken(0.05, props.theme.PRIMARY_COLOR)};
    }
  }
 
  .ant-table {
  
    .ant-table-tbody {
      
      > tr.row-finished.ant-table-row > td {
        background-color: ${props => rgba(props.theme.PRIMARY_COLOR, 0.05)};
      }

      > tr.row-finished.ant-table-row:hover > td {
        background-color: ${props => rgba(props.theme.PRIMARY_COLOR, 0.05)};
      }
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;

    &:not(.ant-input-affix-wrapper-disabled):hover, &:hover {
      border-color: ${props => props.theme.PRIMARY_COLOR};
    }
  }

  .ant-input-affix-wrapper-focused {
    border-color: ${props => props.theme.PRIMARY_COLOR};
    box-shadow: 0 1px 0 0 ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-input {
    border-radius: 5px;

    &:hover {
      border-color: ${props => props.theme.PRIMARY_COLOR};
    }
  }
  
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    &:hover {
      border-color: ${props => props.theme.SELECTER_BORDER};
    }
   
  }

  .ant-input:focus, .ant-input-focused {
    border-bottom-color: ${props => props.theme.PRIMARY_COLOR};
    box-shadow: 0 1px 0 0 ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-btn {
    height: auto;
  }

  .ant-btn:hover, .ant-btn:focus {
    color: ${props => props.theme.PRIMARY_COLOR};
    border-color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-btn-primary:not([disabled]) {
    background-color: ${props => props.theme.PRIMARY_COLOR};
    border-color: ${props => props.theme.PRIMARY_COLOR};

    &:hover {
      color: ${props => props.theme.WHITE};
      background-color: ${props => darken(0.05, props.theme.PRIMARY_COLOR)};
      border-color: ${props => darken(0.05, props.theme.PRIMARY_COLOR)};
    }

    &:focus {
      color: ${props => props.theme.WHITE};
      background-color: ${props => darken(0.1, props.theme.PRIMARY_COLOR)};
      border-color: ${props => darken(0.1, props.theme.PRIMARY_COLOR)};
    }

    &:active {
      color: ${props => props.theme.WHITE};
      background-color: ${props => darken(0.15, props.theme.PRIMARY_COLOR)};
      border-color: ${props => darken(0.15, props.theme.PRIMARY_COLOR)};
    }
  }

  .ant-btn-danger {
    background-color: ${props => props.theme.BUTTON_DANGER_COLOR};
    border-color: ${props => props.theme.BUTTON_DANGER_COLOR};

    &:hover {
      color: ${props => props.theme.WHITE};
      background-color: ${props =>
        darken(0.05, props.theme.BUTTON_DANGER_COLOR)};
      border-color: ${props => darken(0.05, props.theme.BUTTON_DANGER_COLOR)};
    }

    &:focus {
      color: ${props => props.theme.WHITE};
      background-color: ${props =>
        darken(0.1, props.theme.BUTTON_DANGER_COLOR)};
      border-color: ${props => darken(0.1, props.theme.BUTTON_DANGER_COLOR)};
    }

    &:active {
      color: ${props => props.theme.WHITE};
      background-color: ${props =>
        darken(0.15, props.theme.BUTTON_DANGER_COLOR)};
      border-color: ${props => darken(0.15, props.theme.BUTTON_DANGER_COLOR)};
    }
  }

  .ant-btn-dangerous.ant-btn-primary {
    background-color: ${props => props.theme.BUTTON_DANGER_COLOR};
    border-color: ${props => props.theme.BUTTON_DANGER_COLOR};

    &:hover {
      color: ${props => props.theme.WHITE};
      background-color: ${props =>
        darken(0.05, props.theme.BUTTON_DANGER_COLOR)};
      border-color: ${props => darken(0.05, props.theme.BUTTON_DANGER_COLOR)};
    }

    &:focus {
      color: ${props => props.theme.WHITE};
      background-color: ${props =>
        darken(0.1, props.theme.BUTTON_DANGER_COLOR)};
      border-color: ${props => darken(0.1, props.theme.BUTTON_DANGER_COLOR)};
    }

    &:active {
      color: ${props => props.theme.WHITE};
      background-color: ${props =>
        darken(0.15, props.theme.BUTTON_DANGER_COLOR)};
      border-color: ${props => darken(0.15, props.theme.BUTTON_DANGER_COLOR)};
    }
  }

  .ant-btn-link {
    color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-btn-link:hover, .ant-btn-link:focus, .ant-btn-link:active {
    border-color: transparent;
  }

  .ant-radio-button-wrapper:hover {
    color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${props => props.theme.PRIMARY_COLOR};
    border-color: ${props => props.theme.PRIMARY_COLOR};

    &::before {
      background-color: ${props => props.theme.PRIMARY_COLOR};
    }

    &:first-child {
      border-color: ${props => props.theme.PRIMARY_COLOR};
    }

    &:hover {
      color: ${props => props.theme.PRIMARY_COLOR};
      border-color: ${props => props.theme.PRIMARY_COLOR};

      &::before {
        background-color: ${props => props.theme.PRIMARY_COLOR};
      }
    }

    &:focus-within {
      box-shadow: 0 0 0 0.25rem ${props =>
        rgba(props.theme.PRIMARY_COLOR, 0.08)};
    }
  }
`
