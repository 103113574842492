import React from 'react'
import { DEFAULT_THEME } from '@/utils/constants'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '@/containers/Theme/styles'

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={DEFAULT_THEME}>
      {children}

      <GlobalStyle theme={DEFAULT_THEME} />
    </ThemeProvider>
  )
}
export default Theme
