import i18n from '@/i18n'
import { Modal, message } from 'antd'
import { Content } from './styles'

const getErrorKey = (code, defaultError) => {
  switch (code) {
    case 'user.notVerified':
      return 'validate.userNotVerified'

    case 'email.invalid':
      return 'validate.email_invalid'

    case 'email.unregistered':
      return 'validate.email_unregistered'

    case 'user.inactive':
      return 'validate.user_inactive'

    case 'operator.exists':
      return 'validate.operatorExist'

    case 'token.expired':
      return 'validate.token.expired'

    case 'password.invalid':
      return 'validate.password.invalid'

    case 'invalid:username or password are not correct.':
      return 'Username is not correct'

    case 'not a valid account':
      return 'validate.Not a valid account'

    case 'Invalid Range':
      return 'Start/End date must be less than or equal to current date!'

    case 'Date missing':
      return 'Start/End date is missing!'

    case 'invalid:notSuperAdmin':
      return 'validate.Not a valid account'

    case 'Unauthorized':
      return 'Token expired, Please login again.'

    default:
      return defaultError
  }
}

const ErrorMessage = ({ modal = false, text, code, defaultError }) => {
  if (!text && code && defaultError) {
    Modal.destroyAll() //close all modal except the last one

    const errorKey = getErrorKey(code, defaultError)

    const textFromCode = i18n.t(errorKey)

    if (!modal) {
      message.destroy()
      message.error(textFromCode)
    }

    if (!modal && text) {
      message.destroy()
      message.error(text)
    }

    if (modal) {
      Modal.error({
        okButtonProps: {
          danger: true
        },
        content: <Content>{textFromCode}</Content>,
        onOk() {
          message.destroy()
          Modal.destroyAll()
        }
      })
    }
  }

  if (modal && text) {
    Modal.error({
      okButtonProps: { danger: true },
      content: <Content>{text}</Content>,
      onOk() {
        message.destroy()
        Modal.destroyAll()
      }
    })
  }
}

export default ErrorMessage
