import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger' //Adding redux logger

import reducer from './combinedReducers'

const devMiddleWares = []

if (process.env.NODE_ENV === `development`) {
  devMiddleWares.push(logger)
}

export default configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'query/setStartDate',
          'query/setEndDate',
          'screenerQuery/setScreenerStartDate',
          'screenerQuery/setScreenerEndDate',
          'projectBoardResponseQuery/setProjectBoardResponse',
          'projectQuery/setStartDate',
          'projectQuery/setEndDate'
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          'payload.startDate',
          'payload.endDate',
          'payload.projectBoardResponse',
          'payload.startDate',
          'payload.endDate'
        ],
        // Ignore these paths in the state
        ignoredPaths: [
          'query.startDate',
          'query.endDate',
          'screenerQuery.startDate',
          'screenerQuery.endDate',
          'projectBoardResponseQuery.projectBoardResponse',
          'projectQuery.startDate',
          'projectQuery.endDate'
        ]
      }
    }).concat(devMiddleWares)
})
