import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'query',
  initialState: {
    option: '',
    startDate: null,
    endDate: null,
    country: [],
    gender: null,
    usersFromShare: false,
    usersValidInvalid: false,
    valid: false,
    validCondition: '>=',
    validConditionValue: 1,
    invalid: false,
    invalidCondition: '>=',
    invalidConditionValue: 1
  },
  reducers: {
    setOption: (state, action) => {
      state.option = action.payload
    },
    setStartDate: (state, action) => {
      return { ...state, startDate: action.payload }
    },
    setEndDate: (state, action) => {
      return { ...state, endDate: action.payload }
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setGender: (state, action) => {
      state.gender = action.payload
    },
    setUsersFromShare: (state, action) => {
      state.usersFromShare = action.payload
    },
    setUsersValidInvalid: (state, action) => {
      state.usersValidInvalid = action.payload
    },
    setUsersValid: (state, action) => {
      state.valid = action.payload
    },
    setUsersValidCondition: (state, action) => {
      state.validCondition = action.payload
    },
    setUsersValidConditionVlaue: (state, action) => {
      state.validConditionValue = action.payload
    },
    setUsersInvalid: (state, action) => {
      state.invalid = action.payload
    },
    setUsersInvalidCondition: (state, action) => {
      state.invalidCondition = action.payload
    },
    setUsersInvalidConditionVlaue: (state, action) => {
      state.invalidConditionValue = action.payload
    },
    clearAllFilters: state => {
      state.option = '7d'
      state.startDate = ''
      state.endDate = ''
      state.country = []
      state.gender =  null
      state.usersFromShare = false
      state.usersValidInvalid = false
      state.valid = false
      state.validCondition = '>='
      state.validConditionValue = 1
      state.invalid = false
      state.invalidCondition = '>='
      state.invalidConditionValue = 1
    },
    setQuery: (state, action) => {
      const {
        option,
        country,
        gender,
        usersFromShare,
        usersValidInvalid,
        valid,
        validCondition,
        validConditionValue,
        invalid,
        invalidCondition,
        invalidConditionValue
      } = action.payload
      state.option = option
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate)
        : null
      state.endDate = action.payload.startDate
        ? new Date(action.payload.endDate)
        : null
      state.country = country
      state.gender = gender
      state.usersFromShare = usersFromShare
      state.usersValidInvalid = usersValidInvalid
      state.valid = valid
      state.validCondition = validCondition
      state.validConditionValue = validConditionValue
      state.invalid = invalid
      state.invalidCondition = invalidCondition
      state.invalidConditionValue = invalidConditionValue
    }
  }
})

export const {
  setOption,
  setStartDate,
  setEndDate,
  setCountry,
  setGender,
  setUsersFromShare,
  setUsersValidInvalid,
  setUsersValid,
  setUsersValidCondition,
  setUsersValidConditionVlaue,
  setUsersInvalid,
  setUsersInvalidCondition,
  setUsersInvalidConditionVlaue,
  clearAllFilters,
  setQuery,
  setConditionToggle,
  setRemoveCondition
} = slice.actions
export default slice.reducer
