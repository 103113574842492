import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'projectQuery',
  initialState: {
    selectedProject: {},
    projectSurveys: [],
    projectSurveysCopy: [],
    selectedSurveyProducts: [],
    addedSurveys: [],
    showSaveDropdown: false,
    deleteSurveys: [],
    stepCount: 0,
    name: '',
    saveNewProjectName: '',
    option: '',
    startDate: '',
    endDate: '',
    projectId: '',
    management: [{ id: 1, type: 'user', name: 'Lite Userbase' }],
    managementCopy: [{ id: 1, type: 'user', name: 'Lite Userbase' }],
    addChartManagement: [],
    addChartSearch: '',
    projectboardId: '',
    projectClientCode: '',
    selectedFilters: {},
    surveyId: ''
  },
  reducers: {
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload
    },
    setStepCount: (state, action) => {
      state.stepCount = action.payload
    },
    setAllEmpty: (state) => {
      state.selectedProject = {}
      state.selectedSurveyProducts = []
      state.projectSurveys = []
      state.stepCount = 0
    },
    setSurveys: (state, action) => {
      state.projectSurveys = action.payload
    },
    setProductsForSurvey: (state, action) => {
      const surveyId = action.payload.surveyId
      const products = action.payload.products || []
      const objIndex = state.selectedSurveyProducts.findIndex(
        (surveyProds) => surveyProds.surveyId === surveyId
      )
      if (objIndex >= 0) {
        state.selectedSurveyProducts[objIndex].products = products
        if (products.length === 0) {
          state.selectedSurveyProducts = [
            ...state.selectedSurveyProducts
          ].filter((survey) => survey.surveyId !== surveyId)
        }
      } else {
        state.selectedSurveyProducts = [
          ...state.selectedSurveyProducts,
          { surveyId, products }
        ]
      }
    },
    setSelectedSurveyProducts: (state, action) => {
      state.selectedSurveyProducts = action.payload || []
    },
    setSurveysCopy: (state, action) => {
      state.projectSurveysCopy = action.payload
    },
    setAddedSurveys: (state, action) => {
      state.addedSurveys = action.payload
    },
    setShowSaveDropdown: (state, action) => {
      state.showSaveDropdown = action.payload
    },
    setDeleteSurveys: (state, action) => {
      state.deleteSurveys = action.payload
    },
    setProjectName: (state, action) => {
      state.name = action.payload
    },
    setSaveNewProjectName: (state, action) => {
      state.saveNewProjectName = action.payload
    },
    setOption: (state, action) => {
      state.option = action.payload
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload
    },
    setCreatedProject: (state, action) => {
      state.projectboardId = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    setManagement: (state, action) => {
      state.management = action.payload
    },
    setManagementCopy: (state, action) => {
      state.managementCopy = action.payload
    },
    setAddChartManagement: (state, action) => {
      state.addChartManagement = action.payload
    },
    setAddChartSearch: (state, action) => {
      state.addChartSearch = action.payload
    },
    setProjectClientCode: (state, action) => {
      state.projectClientCode = action.payload
    },
    setSurveyId: (state, action) => {
      state.surveyId = action.payload
    },
    setQuery: (state, action) => {
      const {
        name,
        option,
        projectId,
        projectSurveys,
        projectboardId,
        management,
        projectClientCode,
        selectedProject,
        selectedSurveyProducts,
        _id
      } = action.payload
      state.option = option
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate)
        : null
      state.endDate = action.payload.startDate
        ? new Date(action.payload.endDate)
        : null
      state.name = name
      state.projectId = projectId
      state.projectSurveys = projectSurveys
      state.projectSurveysCopy = projectSurveys
      state.projectboardId = projectboardId || _id
      state.management = management
      state.managementCopy = management
      state.projectClientCode = projectClientCode
      state.selectedProject = selectedProject
      if (selectedSurveyProducts) {
        state.selectedSurveyProducts = selectedSurveyProducts
      }
    },

    setAddBoardQuery: (state, action) => {
      const {
        name,
        option,
        projectId,
        projectSurveys,
        projectboardId,
        management,
        projectClientCode,
        selectedProject
      } = action.payload
      state.option = option
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate)
        : null
      state.endDate = action.payload.startDate
        ? new Date(action.payload.endDate)
        : null
      state.name = name
      state.projectId = projectId
      state.projectSurveys = projectSurveys
      state.projectboardId = projectboardId
      state.management = management
      state.projectClientCode = projectClientCode
      state.selectedProject = selectedProject
    },

    clearBoardFilters: (state) => {
      state.selectedProject = {}
      state.projectSurveys = []
      state.stepCount = 0
      state.name = ''
      state.startDate = ''
      state.endDate = ''
      state.projectId = ''
      state.management = [{ id: 1, type: 'user', name: 'Lite Userbase' }]
      state.managementCopy = [{ id: 1, type: 'user', name: 'Lite Userbase' }]
      state.projectboardId = ''
      state.projectClientCode = ''
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload
    }
  }
})
export const {
  setSelectedProject,
  setStepCount,
  setAllEmpty,
  setSurveys,
  setProductsForSurvey,
  setSelectedSurveyProducts,
  setSurveysCopy,
  setAddedSurveys,
  setShowSaveDropdown,
  setDeleteSurveys,
  setProjectName,
  setSaveNewProjectName,
  setOption,
  setProjectId,
  setCreatedProject,
  setStartDate,
  setEndDate,
  setManagement,
  setManagementCopy,
  setAddChartManagement,
  setAddChartSearch,
  setQuery,
  setAddBoardQuery,
  setProjectClientCode,
  clearBoardFilters,
  setSelectedFilters,
  setSurveyId
} = slice.actions
export default slice.reducer
