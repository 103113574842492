import { POST, GET } from '@/utils/axios'

export const defaultSurveyStatsQuery = async formData =>
  await POST('/survey/screenerTasterStats', formData, {
    useAuthorization: true
  })

export const defaultSurveyGraphQuery = async formData =>
  await POST('/survey/screenerTasterGraph', formData, {
    useAuthorization: true
  })

export const surveyListQuery = async () =>
  await GET('/survey', { useAuthorization: true })

export const surveyListAlikeQuery = async value =>
  await GET(`/survey/list/${value}`, { useAuthorization: true })

export const surveyCap = async formData =>
  await POST('/survey/surveyCaps', formData, {
    useAuthorization: true
  })

export const surveyById = async id =>
  await GET(`/survey/${id}`, { useAuthorization: true })
