import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import cache from '@/utils/cache'
import { usersnap } from '@/utils/helperFunctions'

let cacheAuthenticatedUser = null
let cacheImpersonatedUser = null
let userEmail = ''

const checkCacheAuthenticatedUser = () => {
  try {
    const token = cache.getItem('flavorwiki-marketing-token')
    cacheAuthenticatedUser = token ? jwt_decode(token) : null
    if (cacheAuthenticatedUser) {
      usersnap.add(cacheAuthenticatedUser)
    }
  } catch (error) {
    console.error(error)
  }
}
const checkCacheUserEmail = () => {
  try {
    const email = cache.getItem('flavorwiki-marketing-user-email')
    userEmail = email ? email : ''
  } catch (error) {
    console.error(error)
  }
}

checkCacheAuthenticatedUser()
checkCacheUserEmail()

export const slice = createSlice({
  name: 'authentication',
  initialState: {
    isAdminView: false,
    authenticatedUser: cacheAuthenticatedUser,
    impersonatedUser: cacheImpersonatedUser,
    userEmail: userEmail
  },
  reducers: {
    setAuthenticatedUser: (state, action) => {
      cache.setItem('flavorwiki-marketing-token', action.payload)

      const authenticatedUser = jwt_decode(action.payload)
      state.authenticatedUser = authenticatedUser
      if (authenticatedUser && authenticatedUser.id) {
        usersnap.add(authenticatedUser)
      }
    },
    removeAuthenticatedUser: (state, action) => {
      cache.removeItem('flavorwiki-marketing-token')
      cache.removeItem('flavorwiki-marketing-user-email')
      state.authenticatedUser = null
      usersnap.remove()
    },
    setUserEmail: (state, action) => {
      cache.setItem('flavorwiki-marketing-user-email', action.payload)

      state.userEmail = action.payload
    }
  }
})

export const { setAuthenticatedUser, removeAuthenticatedUser, setUserEmail } =
  slice.actions

export default slice.reducer
