import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'projectBoardResponseQuery',
  initialState: {
    projectboardId: '',
    projectBoardResponse: [],
    userStats: { total: '0', verified: '0', completed: '0' },
    userGraph: [],
    surveyStats: [],
    surveyStatsFilters: {},
    surveyStatsCopy: [],
    surveyGraph: [],
    filters: {},
    surveyFlters: [],
    loading: false
  },
  reducers: {
    setCreateProjectResponse: (state, action) => {
      state.projectboardId = action.payload
    },
    setProjectBoardResponse: (state, action) => {
      state.projectBoardResponse = action.payload
    },
    setUserStats: (state, action) => {
      state.userStats = action.payload
    },
    setUserGraph: (state, action) => {
      state.userGraph = action.payload
    },
    setSurveyStats: (state, action) => {
      state.surveyStats = action.payload
    },
    setSurveyStatsCopy: (state, action) => {
      state.surveyStatsCopy = action.payload
    },
    setSurveyGraph: (state, action) => {
      state.surveyGraph = action.payload
    },
    setSurveyFilters: (state, action) => {
      state.surveyFlters = action.payload
    },
    setSurveyStatsFilter: (state, action) => {
      state.surveyStatsFilters = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})
export const {
  setCreateProjectResponse,
  setProjectBoardResponse,
  setUserStats,
  setUserGraph,
  setSurveyStats,
  setSurveyStatsCopy,
  setSurveyGraph,
  setSurveyFilters,
  setSurveyStatsFilter,
  setLoading
} = slice.actions
export default slice.reducer
