import { GET } from '@/utils/axios'

/**
 * @route /data-studio/search-projects
 * @param {String} searchParams {keyword}
 * @returns array of projects and their surveys
 */
export const searchProjects = async (searchParams) =>
  await GET('/data-studio/search-projects', {
    useAuthorization: true,
    params: {
      ...(searchParams.keyword && {
        keyword: searchParams.keyword
      })
    }
  })

/**
 * @route /data-studio/projects-by-ids
 * @param {String} searchParams {projectIds}
 * @returns array of projects and their surveys
 */
export const getProjectsByIds = async (searchParams) =>
  await GET('/data-studio/projects-by-ids', {
    useAuthorization: true,
    params: {
      ...(searchParams.projectIds && {
        projectIds: searchParams.projectIds
      }),
      ...(searchParams.screenerIds && {
        screenerIds: searchParams.screenerIds
      }),
      ...(searchParams.tastingIds && {
        tastingIds: searchParams.tastingIds
      })
    }
  })

/**
 * @route /data-studio/search-screeners
 * @param {String} searchParams {surveyType, keyword}
 * @returns array of screener surveys
 */
export const searchScreeners = async (searchParams) =>
  await GET('/data-studio/search-screeners', {
    useAuthorization: true,
    params: {
      ...(searchParams.surveyType && {
        'survey-type': searchParams.surveyType
      }),
      ...(searchParams.keyword && {
        keyword: searchParams.keyword
      })
    }
  })

/**
 * @route /data-studio/search-tastings
 * @param {String} searchParams {surveyType, keyword}
 * @returns array of tasting surveys
 */
export const searchTastings = async (searchParams) =>
  await GET('/data-studio/search-tastings', {
    useAuthorization: true,
    params: {
      ...(searchParams.surveyType && {
        'survey-type': searchParams.surveyType
      }),
      ...(searchParams.keyword && {
        keyword: searchParams.keyword
      })
    }
  })

/**
 * @route /data-studio/screener-surveys-stats
 * @param {Object} searchParams {currentScreeners}
 * @returns array of screeners and their summary stats
 */
export const getScreenerStats = async (searchParams) =>
  await GET('/data-studio/screener-surveys-stats', {
    useAuthorization: true,
    params: {
      ...(searchParams.currentScreeners && {
        screeners: searchParams.currentScreeners
      })
    }
  })

/**
 * @route /data-studio/tasting-surveys-stats
 * @param {Object} searchParams {currentTastings}
 * @returns array of tastings and their summary stats
 */
export const getTastingStats = async (searchParams) =>
  await GET('/data-studio/tasting-surveys-stats', {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        tastings: searchParams.currentTastings
      })
    }
  })

/**
 * @route /data-studio/survey-funnel
 * @param {Object} searchParams {currentScreeners, currentTastings}
 * @returns array of funnel stats (total 5 items)
 */
export const getSurveyFunnelStats = async (searchParams) => {
  return await GET('/data-studio/survey-funnel', {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        'current-tastings': searchParams.currentTastings
      }),
      ...(searchParams.currentScreeners && {
        'current-screeners': searchParams.currentScreeners
      })
    }
  })
}

/**
 * @route /data-studio/tasting-predicted-complete
 * @param {Object} searchParams {currentTastings}
 * @returns array of tastings and their predicted dates
 */
export const getPredictedCompleteData = async (searchParams) =>
  await GET('/data-studio/tasting-predicted-complete', {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        'current-tastings': searchParams.currentTastings
      })
    }
  })

/**
 * @route /data-studio/tasting-predicted-complete
 * @param {Object} searchParams {currentTastings, sortName, sortValid}
 * @returns array of tastings and their predicted dates
 */
export const getProductsData = async (searchParams) =>
  await GET(`/data-studio/valid-products`, {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        'current-tastings': searchParams.currentTastings
      }),
      ...(searchParams.sortName && { 'sort-name': searchParams.sortName }),
      ...(searchParams.sortValid && { 'sort-valid': searchParams.sortValid })
    }
  })

/**
 * @route /data-studio/average-daily
 * @param {Object} searchParams {surveyType, page, size, currentTastings}
 * @returns array of tastings and their predicted dates
 */
export const getAverageDaily = async (searchParams) =>
  await GET(`/data-studio/average-daily`, {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        'current-tastings': searchParams.currentTastings
      })
    }
  })

/**
 * @route /data-studio/cohort-data
 * @param {Object} searchParams {currentScreeners, currentTastings, dateOption, startDate, endDate}
 * @returns
 */
export const getCohortCards = async (searchParams) =>
  await GET('/data-studio/cohort-data', {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        'current-tastings': searchParams.currentTastings
      }),
      ...(searchParams.currentScreeners && {
        'current-screeners': searchParams.currentScreeners
      }),
      ...(searchParams.dateOption && {
        'date-option': searchParams.dateOption
      }),
      ...(searchParams.startDate && {
        'start-date': searchParams.startDate
      }),
      ...(searchParams.endDate && {
        'end-date': searchParams.endDate
      })
    }
  })

/**
 * @route /data-studio/cohort-data
 * @param {Object} searchParams {currentScreeners, currentTastings, dateOption, startDate, endDate}
 * @returns
 */
export const getProductsCumulative = async (searchParams) =>
  await GET('/data-studio/products-cumulative', {
    useAuthorization: true,
    params: {
      ...(searchParams.currentTastings && {
        'current-tastings': searchParams.currentTastings
      })
    }
  })

/**
 * @route /survey-enrollment
 * @returns latest enrollment data in moment
 */
export const getLatestUpdate = async () =>
  await GET(`/survey-enrollment/latest-update`, { useAuthorization: true })

/**
 * @route /data-studio/cumulative-screeners
 * @param {Object} searchParams {currentScreeners}
 * @returns array of screener survey stats (max 7 items)
 */
export const getCumulativeScreeners = async (searchParams) => {
  return await GET('/data-studio/cumulative-screeners', {
    useAuthorization: true,
    params: {
      ...(searchParams.surveyType && {
        'survey-type': searchParams.surveyType
      }),
      ...(searchParams.surveyIds && {
        'current-screeners': searchParams.surveyIds
      })
    }
  })
}

/**
 * @route /data-studio/cumulative-tastings
 * @param {Object} searchParams {currentTastings}
 * @returns array of tasting survey stats (max 7 items)
 */
export const getCumulativeTastings = async (surveyIds) => {
  return await GET('/data-studio/cumulative-tastings', {
    useAuthorization: true,
    params: { 'current-tastings': surveyIds }
  })
}
