import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  surveyList: [],
  surveyStats: { total: '0', unprocessed: '0', completed: '0' },
  filters: {},
  finalResult: [],
  surveyCapRes: [],
  surveyCountry: '',
  surveyImage: '',
  surveyName: ''
}

const slice = createSlice({
  name: 'screenerResponse',
  initialState,
  reducers: {
    setSurveyList: (state, action) => {
      state.surveyList = action.payload
    },
    setSurveyStats: (state, action) => {
      state.surveyStats = action.payload
    },
    setScreenerFilters: (state, action) => {
      state.filters = action.payload
    },
    setScreenerGraph: (state, action) => {
      state.finalResult = action.payload
    },
    setSurveyCapApiRes: (state, action) => {
      state.surveyCapRes = action.payload
    },
    setSurveyCountry: (state, action) => {
      state.surveyCountry = action.payload
    },
    setSurveyImage: (state, action) => {
      state.surveyImage = action.payload
    },
    setSurveyName: (state, action) => {
      state.surveyName = action.payload
    }
  }
})
export const {
  setSurveyList,
  setSurveyStats,
  setScreenerFilters,
  setScreenerGraph,
  setSurveyCapApiRes,
  setSurveyCountry,
  setSurveyImage,
  setSurveyName
} = slice.actions
export default slice.reducer
